<template>
  <div class="page_stat">  
    <el-row type="flex" justify="space-between" align="center">
      <el-col>
        <el-page-header
          content="Статистика"
          title="на главную"
          @back="$router.push('/')"
        ></el-page-header>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-form>
      <el-row>
        <el-col :span="7">
          <el-form-item label="Период">
            <el-date-picker
              v-model="period"
              type="daterange" 
               unlink-panels
              @change="getStat(period)"
             >
           </el-date-picker>
           </el-form-item> 
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('month')">Месяц</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('week')">Неделя</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('day')">День</el-button>
        </el-col>
      </el-row>
     
    </el-form>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="8">
  <el-descriptions class="stat" title="Общая информация" :column="2" size="medium" border>
      <el-descriptions-item>
        <template slot="label">
          Лидов вошло
        </template>
        {{stat.LEADS_ADD}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Брак
        </template>
        {{stat.BRAK}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          В работе
        </template>
        {{stat.WORK}}
      </el-descriptions-item>
       <el-descriptions-item>
        <template slot="label">
          Провалено
        </template>
        {{stat.PROVAL}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Передано
        </template>
        {{stat.LOGIST}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Конверсия
        </template>
        {{stat.CONVERSION}}%
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Закрыто всего
        </template>
        {{stat.CLOSED_ALL}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Закрыто прошлых
        </template>
        {{stat.CLOSED_LAST}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Отклонение от средней
        </template>
        {{stat.DIVERGENCE}}
      </el-descriptions-item>
       <el-descriptions-item>
        <template slot="label">
          Чистота
        </template>
        {{stat.CLEAN}}%
      </el-descriptions-item>
       <el-descriptions-item>
        <template slot="label">
          CPL чистая
        </template>
        {{stat.CPL}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          Расходы директ
        </template>
        {{stat.DIRECT_R}}
      </el-descriptions-item>
    </el-descriptions>
      </el-col>
      <el-col :span="8">
        <apexchart type="line" :options="options" :series="stat.GRAPHICS.G1"></apexchart>
      </el-col>
      <el-col :span="8">
        <apexchart type="bar" :options="options2" :series="stat.GRAPHICS.G2"></apexchart>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <apexchart type="line" :options="options3" :series="stat.GRAPHICS.G3"></apexchart> 
      </el-col>
      <el-col :span="8">
        <apexchart type="polarArea" :options="operatorsOptions" :series="Object.values(stat.GRAPHICS.G4)"></apexchart>
      </el-col>
      <el-col :span="8">
        <apexchart type="polarArea" :options="operatorsOptions2" :series="Object.values(stat.GRAPHICS.G5)"></apexchart>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
          <apexchart type="line" :options="operatorsOptions3" :series="Object.values(stat.GRAPHICS.G6)"></apexchart>
      </el-col>
    </el-row>
  
    
    

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      period: [moment().subtract(2, 'weeks'), moment()],//[moment("2022-04-01T00:00:00"), moment("2022-04-01T23:59:59")]//[moment().tz("Europe/Moscow").subtract("2", "weeks"), moment().tz("Europe/Moscow")]
       options: {
          dataLabels: {
    enabled: false
  },
         xasis: {
           type: "datetime",
         },
         stroke: {
           width: 3
         }
       },
       options2: {
         dataLabels: {
              enabled: false
            },

            xasis: {
           type: "datetime",
         },
           
         stroke: {
           width: 3
         },
         chart: {
           stacked: true
         },      
       },
       options3: {

         stroke: {
           width: 3
         },
         dataLabels: {
              enabled: false
            },
         chart: {
           type: 'line',
           stacked: false
         },
         yaxis: [
              {
                title: {
                  text: "CPL"
                },
                axisTicks: {
                  show: true,
                },
                labels: {
                  style: {
                    colors: '#008FFB',
                  }
                },
                tooltip: {
                  enabled: true
                }
              },
              {
                show: false,
             
                opposite: true,
                axisTicks: {
                  show: true,
                },
                labels: {
                  style: {
                    colors: '#00E396',
                  }
                },
                title: {
                  text: "CPL чистая",
                  style: {
                    color: '#00E396',
                  }
                },
              },
              {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                  show: true,
                },
                labels: {
                  style: {
                    colors: '#FEB019',
                  },
                },
                title: {
                  text: "Расходы директ",
                  style: {
                    color: '#FEB019',
                  }
                }
              },
            ],
       }
    }
  },
  async created() {
    this.getStat(this.period);
    this.bxUsers();
  },
  methods: {
    ...mapActions(['getStat', 'bxUsers']),
    changeDate(type) {
      const periods = {
        month: [moment().subtract('1', 'month').set({h:0, m:0, s:0}), moment().subtract('1', 'day').set({h:23, m:59, s: 59})],
        week: [moment().subtract('1', 'week').set({h:0, m:0, s:0}), moment().subtract('1', 'day').set({h:23, m:59, s: 59})],
        day: [moment().subtract('1', 'day').set({h:0, m:0, s:0}), moment().subtract('1', 'day').set({h:23, m:59, s: 59})],
      }
      this.getStat(periods[type])
    }
  },
  computed: {
    ...mapGetters(["stat", "loading", "bxusers"]),
    operatorsOptions() {
      return {
        title: {
          text: 'Распределение лидов по операторам',
          align: "center"
        },
        labels: Object.keys(this.stat.GRAPHICS.G4).map(u => {
          const user = this.bxusers.find(e => e.ID == u);
          if(user) return user.NAME+" "+user.LAST_NAME;
          return "Не размечено"
        })
      }
    },
    operatorsOptions2() {
      return {
        title: {
          text: 'Вклад в продажи',
          align: "center"
        },
        yaxis: {
          min: 20
        },
        labels: Object.keys(this.stat.GRAPHICS.G5).map(u => {
          const user = this.bxusers.find(e => e.ID == u);
          if(user) return user.NAME+" "+user.LAST_NAME;
          return "Не размечено"
        })
      }
    },
    operatorsOptions3() {
      return {
        title: {
          text: 'Конверсия, доля лидов и вклад в продажи',
          align: "center"
        },
        chart: {
          stacked: false
        },
        labels: this.stat.G6_USERS.map(u => {
          const user = this.bxusers.find(e => e.ID == u);
          if(user) return user.NAME+" "+user.LAST_NAME; 
          return "Не размечено"
        })
      }
    }
  }
}
</script>

<style>
.page_stat {
  padding: 40px;
}
.stat {
  max-width: 600px;
}
</style>